import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useDebouncedState = <T>(
  initialValue?: T,
  delay = 500,
): [T | undefined, T | undefined, Dispatch<SetStateAction<T | undefined>>] => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler: NodeJS.Timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return (): void => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [value, debouncedValue, setValue];
};
