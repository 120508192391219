'use client';
import { NProgress, useRouter } from '@sentinel/manana/router';
import { usePathname, useSearchParams } from 'next/navigation';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

import type { FC } from 'react';

type Props = {
  totalItems: number;
  limit: number;
  beforePagesToShow?: number;
  afterPagesToShow?: number;
  mode?: 'page' | 'offset';
};

export const Pagination: FC<Props> = ({
  totalItems,
  limit,
  beforePagesToShow = 3,
  afterPagesToShow = 3,
  mode = 'page',
}) => {
  const { push } = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const queryMode = searchParams?.get(mode);

  const modeValue = queryMode && typeof queryMode === 'string' ? parseInt(queryMode) : undefined;

  const currentPage = !modeValue
    ? 1
    : mode === 'page'
    ? modeValue
    : Math.round(modeValue / limit) + 1;

  const goToPage = async (page: number): Promise<void> => {
    const modeValue = mode === 'page' ? page : (page - 1) * limit;
    const params = new URLSearchParams(searchParams);
    params.set(mode, String(modeValue));

    if (mode === 'page' && modeValue === 1) {
      params.delete('page');
    }

    if (mode === 'offset' && modeValue === 0) {
      params.delete('offset');
    }

    NProgress.start();
    push(`${pathname}?${params}`, { scroll: false, showProgressBar: true });
  };

  const numberOfPages = Math.ceil(totalItems / limit);
  if (numberOfPages <= 1) return null;

  const start = currentPage - beforePagesToShow < 1 ? 1 : currentPage - beforePagesToShow;
  const end =
    currentPage + afterPagesToShow > numberOfPages ? numberOfPages : currentPage + afterPagesToShow;

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return (
    <div>
      <div className="flex items-center justify-center py-6">
        <button
          className={`block rounded-l-full border-l border-t border-b px-2 py-2 h-auto ${
            currentPage === 1 ? 'opacity-50' : ''
          }`}
          type="button"
          disabled={currentPage === 1}
          onClick={(): void => {
            if (currentPage === 1) return;
            void goToPage(currentPage - 1);
          }}>
          <FaAngleDoubleLeft />
        </button>
        {pages.map((page) => (
          <button
            key={page}
            type="button"
            onClick={(): void => {
              void goToPage(page);
            }}
            className={`block border px-2 py-1 text-center md:w-10 ${
              currentPage === page ? 'bg-primary text-white' : ''
            }`}>
            {page}
          </button>
        ))}

        <button
          className={`block rounded-r-full border-l border-t border-b px-2 py-2 ${
            currentPage === numberOfPages ? 'opacity-50' : ''
          }`}
          type="button"
          disabled={currentPage === numberOfPages}
          onClick={(): void => {
            if (currentPage === numberOfPages) return;
            void goToPage(currentPage + 1);
          }}>
          <FaAngleDoubleRight />
        </button>
      </div>
    </div>
  );
};
