'use client';

import { useRouter, NProgress } from '@sentinel/manana/router';
import { useDebouncedState } from '@sentinel/use-debounce-state';
import { usePathname, useSearchParams } from 'next/navigation';
import { type FC, useCallback, useEffect, useState } from 'react';
import { z } from 'zod';
import { parseSearchParams } from 'zod-search-params';

import { api } from '@/utils/api';

type Props = {
  categories: Awaited<ReturnType<typeof api.extras.listCategories>>;
  modxVersions: Awaited<ReturnType<typeof api.releases.getMinorVersions>>;
  liveSearch?: boolean;
};

const schema = z.object({
  supports: z.string().optional().catch(undefined),
  category: z.string().optional().catch(undefined),
  search: z.string().optional().catch(undefined),
});

export const SearchForm: FC<Props> = ({ categories, modxVersions, liveSearch = false }) => {
  const { push } = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const {
    search: paramsSearch,
    supports: paramsSupports,
    category: paramsCategory,
  } = parseSearchParams(schema, searchParams);

  const [rawSearch, search, setSearch] = useDebouncedState(paramsSearch);
  const [supports, setSupports] = useState(paramsSupports);
  const [category, setCategory] = useState(paramsCategory);

  const createQueryString = useCallback(
    (newParams: [string, string | undefined][]) => {
      const params = new URLSearchParams(searchParams);
      params.delete('page');
      params.delete('offset');
      newParams.forEach(([name, value]) => {
        if (value) {
          params.set(name, value);
        } else {
          params.delete(name);
        }
      });

      return params.toString();
    },
    [searchParams],
  );

  const triggerSearch = async (): Promise<void> => {
    if (paramsSearch === search && paramsSupports === supports && paramsCategory === category) {
      return;
    }

    NProgress.start();

    const queryString = createQueryString([
      ['search', rawSearch],
      ['supports', supports],
      ['category', category],
    ]);

    push(pathname + '?' + queryString, { scroll: false });
  };

  useEffect(() => {
    if (!liveSearch) return;

    if (paramsSearch === search && paramsSupports === supports && paramsCategory === category) {
      return;
    }

    NProgress.start();
    push(
      pathname +
        '?' +
        createQueryString([
          ['search', search],
          ['supports', supports],
          ['category', category],
        ]),
      { scroll: false },
    );
  }, [
    liveSearch,
    paramsSearch,
    paramsSupports,
    paramsCategory,
    category,
    pathname,
    createQueryString,
    push,
    search,
    supports,
  ]);

  return (
    <div className="flex gap-4 lg:gap-8 py-4 flex-wrap w-auto">
      <label className="w-full lg:w-auto">
        <span className="sr-only">Choose Category</span>
        <select
          name="category"
          className="form-select w-full"
          value={category || ''}
          onChange={(e): void => {
            if (!e.target.value) {
              setCategory(undefined);
              return;
            }

            setCategory(e.target.value);
          }}>
          <option value="">Browse By Category</option>
          {categories._embedded.categories.map((category) => (
            <option
              key={category.category_id}
              value={category.key}
              dangerouslySetInnerHTML={{ __html: category.name }}
            />
          ))}
        </select>
      </label>
      <label className="w-full lg:w-auto">
        <span className="sr-only">Choose MODX Version</span>
        <select
          name="supports"
          className="form-select w-full"
          value={supports || ''}
          onChange={(e): void => {
            if (!e.target.value) {
              setSupports(undefined);
              return;
            }

            setSupports(e.target.value);
          }}>
          <option value="">Choose MODX Version</option>
          {modxVersions._embedded.release_minor_versions
            .filter((version) => Number(version.key) >= 2.6)
            .sort((a, b) => {
              const x = b.key.replace(/\d+/g, (n) => String(+n + 100000));
              const y = a.key.replace(/\d+/g, (n) => String(+n + 100000));

              return Number(x) - Number(y);
            })
            .map((minorVersion) => (
              <option key={minorVersion.key} value={minorVersion.key}>
                {minorVersion.key}
              </option>
            ))}
        </select>
      </label>
      <label className="w-full lg:w-auto">
        <span className="sr-only">Search by Keyword</span>
        <input
          type="text"
          name="search"
          placeholder="Search by Keyword"
          className="form-control w-full"
          value={rawSearch || ''}
          onKeyUp={(e): void => {
            if (e.key === 'Enter') {
              void triggerSearch();
            }
          }}
          onChange={(e): void => {
            if (!e.target.value) {
              setSearch(undefined);
              return;
            }

            setSearch(e.target.value);
          }}
        />
      </label>
      <div className="w-full lg:w-auto">
        <button type="button" onClick={triggerSearch} className="btn btn-primary btn-sqr w-full">
          <span className="sr-only">Search</span>
          <span>
            <svg
              className="svg-inline--fa fa-search"
              aria-hidden="true"
              style={{ height: '16px', width: '16px', display: 'inline-block' }}
              focusable="false"
              data-prefix="fas"
              data-icon="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              data-fa-i2svg="">
              <path
                fill="currentColor"
                d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};
